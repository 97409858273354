export default {
  methods: {
    formatNote (note) {
      if (note) {
        const hrefs = note.replace(/(https?:\/\/)([^ ]+)/g, '<a target="_blank" href="$&">$2</a>')
        return hrefs.replace(/AID:\s([a-z0-9.]+)/g, 'AID: <a target="_blank" rel="noopener" href="http://app.arkivdigital.se/production/index.html?aid=$1&page=main/index">$1</a>')
          .replace(/bildid: ([A-Z0-9_]+)/g, 'bildid: <a target="_blank" rel="noopener" href="https://sok.riksarkivet.se/bildvisning/$1">$1</a>')
          .replace(/\{\\([\w]+)\s([^}]*)\}/g, '<span class="$1">$2</span>')
      }
      return null
    }
  }
}

<template>
  <header>
      <button class="menu" @click="menuClick" aria-label="Visa/dölj navigering">Meny</button>
      <router-link class="home" to="/">
          <button aria-label="Startsida">Start</button>
          <span>{{websiteTitle}}</span>
      </router-link>
      <button class="search" @click="searchOpen=!searchOpen" aria-label="Sök">Sök</button>
      <nav :class="{show:menuOpen}" @click="menuClick">
          <router-link to="/nyheter">Nyheter</router-link>
          <a v-if="appId===1" class="hidden" href="https://luggude.slektforskning.se/wiki/Huvudsida">Luggude Wiki</a>
          <a v-if="appId===2" class="hidden" href="/dombocker">Domböcker</a>
          <router-link to="/om">Om</router-link>
          <router-link to="/kontakt">Kontakt</router-link>
      </nav>
      <form action="/personer" :class="{show:searchOpen}">
          <input v-model="search" name="q" autocomplete="off" type="search" placeholder="Sök (namn, ort, årtal)" aria-label="Sök">
          <aside v-if="searchResult">
            <div v-if="searchResult.persons">
              <span><b>Personer (<router-link class="showall" :to="'/personer?q=' + debouncedSearch" @click="searchClick">Visa alla träffar</router-link>)</b></span>
              <ul>
                <li v-for="person in searchResult.persons" :key="person">
                  <PersonLinkComponent :person="person" @personClicked="searchClick" />
                </li>
              </ul>
            </div>
            <div v-if="searchResult.places">
              <span><b>Platser (<router-link class="showall" :to="'/platser?q=' + debouncedSearch" @click="searchClick">Visa alla träffar</router-link>)</b></span>
              <ul>
                <li v-for="place in searchResult.places" :key="place">
                  <PlaceNameComponent :placeName="place.placeName" @placeClicked="searchClick"/>
                </li>
              </ul>
            </div>
          </aside>
      </form>
  </header>
</template>

<script>
import Fetch from '../../mixins/fetch'
export default {
  mixins: [
    Fetch
  ],
  data () {
    return {
      menuOpen: false,
      searchOpen: false,
      debouncedSearch: '',
      timeout: null,
      searchResult: null
    }
  },
  computed: {
    search: {
      get () {
        return this.debouncedSearch
      },
      set (val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.debouncedSearch = val
          if (val.length > 2) {
            this.doSearch()
          } else if (val.length === 0) {
            this.searchResult = null
          }
        }, 300)
      }
    }
  },
  methods: {
    menuClick () {
      this.menuOpen = !this.menuOpen
    },
    searchClick () {
      this.debouncedSearch = this.searchResult = null
      this.searchOpen = !this.searchOpen
    },
    doSearch () {
      this.fetchData('/search?q=' + this.debouncedSearch + '&page=1&l=8').then(data => {
        if (data && (data.persons || data.places)) {
          this.searchResult = data
          this.searchOpen = true
        }
      })
    }
  }
}
</script>
<style lang="scss">
  .showall {
    display:inline;
  }
  aside {
     @media (min-width: 768px){
        right: 23px;
        font-size: 1rem
     }
    ul {
        margin: 5px 0px;
        flex-direction: column
    }
    > span {
        color: #000;
    }
    a {
        color: var(--maincolor);
    }
    position:fixed;
    padding: .375rem .75rem;
    background-color: #fff;
    border: 1px solid #ced4da;
    margin-top: 5px;
    border-radius: .25rem;
    font-size: .8rem
  }
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import placeName from './components/subcomponents/PlaceNameComponent.vue'
import Events from './components/subcomponents/EventsComponent.vue'
import navigation from './components/subcomponents/NavigationComponent.vue'
import personLink from './components/subcomponents/PersonLinkComponent.vue'
import tabs from './components/subcomponents/TabsComponent.vue'
import { createHead, Head } from '@vueuse/head'
// eslint-disable-next-line import/no-absolute-path

let subDomain = window.location.hostname.split('.')[0]
if (subDomain === 'localhost') subDomain = 'brandt'
fetch('/theme/' + subDomain + '/config.json').then(response => response.json()).then(config => {
  const app = createApp(App)
  const props = app.config.globalProperties
  props.appId = config.appId
  props.apiUrl = config.apiUrl
  props.websiteTitle = config.title
  props.maxGen = config.maxGen
  props.googleApiKey = config.googleApiKey
  props.captchaKey = config.captchaKey
  props.alphabet = [...'abcdefghijklmnopqrstuvwxyzåäö']
  props.treeWidths = props.treeData = []
  props.subDomain = subDomain
  props.blobStorage = config.blobStorage

  app.component('HeadComponent', Head)
  app.component('NavigationComponent', navigation)
  app.component('PersonLinkComponent', personLink)
  app.component('TabsComponent', tabs)
  app.component('EventsComponent', Events)
  app.component('PlaceNameComponent', placeName)
  app.use(createHead()).use(router).mount('body')
})

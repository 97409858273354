<template>
    <nav class="tabs">
        <ul v-if="$parent.person">
            <li>
                <PersonLinkComponent
                    :person="$parent.person"
                    :class="{active:activeTab=='Person'}">Personakt</PersonLinkComponent>
            </li>
            <li v-if="activeTab === 'Antavla' || ($parent.person && $parent.person.ParentFamilies.length > 0)">
                <PersonLinkComponent
                    :person="$parent.person"
                    :class="{active:activeTab=='Antavla'}"
                    :rel="'nofollow'"
                    :addition="'.Antavla'">Antavla</PersonLinkComponent>
            </li>
            <li v-if="$parent.person.DnaSamples.length>0">
                <PersonLinkComponent
                    :person="$parent.person"
                    :class="{active:activeTab=='Dna'}"
                    :rel="'nofollow'"
                    :addition="'.Dna'">DNA ({{$parent.person.DnaSamples.length}})</PersonLinkComponent>
            </li>
            <li v-if="showMap">
                <PersonLinkComponent
                    :person="$parent.person"
                    :class="{active:activeTab=='Map'}"
                    :rel="'nofollow'"
                    :addition="'.Karta'">Karta</PersonLinkComponent>
            </li>
            <li v-if="$parent.person.Pictures">
                <PersonLinkComponent
                    :person="$parent.person"
                    :class="{active:activeTab=='Media'}"
                    :rel="'nofollow'"
                    :addition="'.Media'">Media ({{$parent.person.Pictures.length}})</PersonLinkComponent>
            </li>
        </ul>
        <ul v-else-if="($parent.places || $parent.place) && !$parent.searchResult">
            <li v-if="$parent.places || $parent.place">
                <router-link v-if="$parent.places" :to="'/platser/' + $parent.letter" :class="{active:!$parent.place}">Platser ({{$parent.places.length}})</router-link>
                <router-link v-else :to="'/platser/' + $parent.letter" :class="{active:!$parent.place}">Platser</router-link>
            </li>
            <li v-if="$parent.place">
                <a :class="{active:$parent.place}" href="">Personer ({{$parent.place.persons.length}})</a>
            </li>
        </ul>
        <ul v-else-if="$parent.searchResult">
            <li>
                <router-link :to="'/platser?q=' + $parent.q" :class="{active:activeTab=='Map'}">
                Platser ({{$parent.searchResult.places?$parent.searchResult.places.length:0}})
                </router-link>
            </li>
            <li>
                <router-link :to="'/personer?q=' + $parent.q" :class="{active:activeTab=='Persons'}">
                Personer
                </router-link>
            </li>
        </ul>
    </nav>
</template>
<style lang="scss">
.tabs ul {
  a {
    @media (max-width: 767px) {
        padding: 0.5rem 0.5rem
    }
    &.active {
        color: #495057;
        border-color: #dee2e6 #dee2e6 #fff
    }
    &:hover {
        border-color: #e9ecef #e9ecef #dee2e6
    }
    font-weight: 400;
    text-decoration: none;
    display:flex;
    border: 1px solid transparent;
    padding: .5rem 1rem
  }
  display: flex;
  height: 2.35rem;
  background-color: #fff;
  border-bottom: 1px solid #dee2e6;
  margin: 0px
}
</style>
<script>
export default {
  props: {
    activeTab: null
  },
  computed: {
    showMap () {
      const person = this.$parent.person
      return this.$parent.person && process.env.VUE_APP_GOOGE_MAPS_KEY && (
        (person.EventsCount && person.EventsCount > 0) ||
        (person.Events && person.Events.find(i => i.PlaceLatitude)) ||
        (person.Families && person.Families.find(i => i.Events && i.Events.find(i => i.PlaceLatitude))))
    }
  }
}
</script>

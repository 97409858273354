<template>
    <router-link v-if="!individualLinks" :to="'/platser/' + placeName.replace(/\s\([A-Z]{1,2}\)/ig, '').replace(/,\s?/ig, '/').replace(/\s(nr\s[0-9]+)$/, '/$1')" class="placename" @click="$emit('placeClicked')">{{placeName}}</router-link>
    <template v-else v-for="link in links" :key="link">
        <router-link :to="link.Url">{{link.Name}}</router-link>{{link.Spacer}}
    </template>
</template>
<script>
export default {
  props: {
    placeName: null,
    individualLinks: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    links () {
      const places = []
      if (this.placeName) {
        let root = '/platser'
        this.placeName.split(',').reverse().forEach(element => {
          root += '/' + element.trim().replace(/\s\([A-Z]{1,2}\)$/i, '')
          places.push({ Name: element.trim(), Url: root, Spacer: ', ' })
        })
        places[0].Spacer = ''
      }
      return places.reverse()
    }
  }
}
</script>

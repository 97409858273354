<template>
  <TabsComponent v-if="person" :activeTab="activeTab"/>
  <HeadComponent>
    <title>{{title}}</title>
    <meta name="description" :content="description" />
    <meta name="robots" content="noindex, follow" v-if="suggestions" />
  </HeadComponent>
  <article v-if="person">
    <template v-if="activeTab=='Person' || activeTab=='Dna'">
      <div class="warning" v-if="activeTab=='Dna'"><b>OBS!</b> Det är ättlingar (enligt traditionell släktforskning) på raka fädernet eller mödernet som gjort DNA-proven, inte aktuell person. Felaktigheter kan förekomma och haplogruppernas namn kan ändras.</div>
      <div class="warning" v-if="person.ParentFamilies.some(i=>i.FamilyRel==1||i.FatherRel==7||i.MotherRel==7)"><b>OBS!</b> Det saknas tillräckliga belägg för att anse att en eller båda av föräldrarna är säkerställd(a). Här har jag delat med mig av arbetshypotesen kring vem/vilka det <i>kan</i> vara.</div>
      <picture v-if="person.Pictures&&person.Pictures.length>0">
        <img
          :alt="person.Pictures[0].Alt"
          :title="person.Pictures[0].Description"
          :src="blobStorage + '/' + person.Pictures[0].FileName.substr(0, person.Pictures[0].FileName.lastIndexOf('.')) + '.webp'"
          :srcset="blobStorage + '/' + person.Pictures[0].FileName.substr(0, person.Pictures[0].FileName.lastIndexOf('.')) + '.webp ' + person.Pictures[0].Width + 'w'"
          :sizes="person.Pictures[0].Width + 'px'"
          :height="person.Pictures[0].Height"
          :width="person.Pictures[0].Width" />
      </picture>
      <h1><PersonLinkComponent :person="person" :showPeriod="false" :withLink="false" :withTitle="true" /></h1>
      <sup v-if="person.SourceLinks">
        {{person.SourceLinks}}
      </sup>
      <span v-if="person.Relation">
         ({{person.Relation}})
      </span>
      <div>{{getOccupation(person)}}</div>
      <template v-if="activeTab=='Person'">
        <section>
          <template v-for="family in person.ParentFamilies" :key="family">
            <template v-for="parent in ['Father', 'Mother']" :key="parent">
              <div v-if="family[parent]">
                <b>{{getRelationship(family, parent)}}:</b> <PersonLinkComponent :person="family[parent]"/>
              </div>
            </template>
          </template>
          <EventsComponent :events="person.Events" />
        </section>
        <section v-for="family in person.Families" :key="family">
          <b>Familj med </b>
          <PersonLinkComponent v-if="family.Spouse" :person="family.Spouse" />
          <template v-else>okänd</template>
          <EventsComponent :events="family.Events" />
          <template v-if="family.Children.length > 0">
            <ul>
              <li><b>Barn</b></li>
              <li v-for="child in family.Children" :key="child">
                <PersonLinkComponent :person="child" />
              </li>
            </ul>
          </template>
        </section>
        <section v-if="person.Notes">
          <b>Noteringar</b>
          <div v-html="formatNote(person.Notes)"></div>
        </section>
        <section v-if="person.Sources.length > 0">
          <ul class="sources">
            <li><b>Källor</b></li>
            <li v-for="(source, index) in person.Sources" :key="source">
              {{index +1}}. <span v-html="formatNote(source.Name)"></span><span v-if="source.Pages && source.Pages.length==1" v-html="', ' + formatNote(source.Pages[0])"></span>
              <div v-if="source.Author"><i>Författare: </i>{{source.Author}}</div>
              <div v-if="source.Publishing"><i>Publikation: </i>{{source.Publishing}}</div>
              <ul v-if="source.Pages && source.Pages.length>1">
                <li v-for="(page, i) in source.Pages" :key="page">
                  {{index +1}}{{alphabet[i]}}. <span v-html="formatNote(page)"></span>
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </template>
      <template v-else>
        <section>
          <div v-for="(sample, index) in person.DnaSamples" :key="sample">
            <b>{{sample.Type}}:</b> {{sample.Haplogroup}}<sup>{{index+1}})</sup>
          </div>
        </section>
        <section>
          <ul class="sources">
            <li><b>Källor</b></li>
            <li v-for="(sample, index) in person.DnaSamples" :key="sample">
              {{index+1}}. <span>{{sample.Company}}</span>
              <div v-if="sample.Notes">
                <i>{{sample.Notes}}</i>
              </div>
            </li>
          </ul>
        </section>
      </template>
      <i>Senast ändrad: {{person.ChangeDate}}</i>
    </template>
    <div v-else v-for="picture in person.Pictures" :key="picture">
      <img :title="picture.Description" :alt="picture.Alt" :src="blobStorage + '/' + picture.FileName" />
      <div>{{picture.Description}}</div>
    </div>
  </article>
  <article v-else-if="suggestions" class="error">
    <h1>{{notFoundText}}</h1>
    <img :src="'/img/404_' + subDomain + '.png'" :alt="notFoundText">
    <template v-if="suggestions.persons">
      <div>Förslag:</div>
      <ul>
        <li v-for="person in suggestions.persons" :key="person">
          <PersonLinkComponent :person="person" />
        </li>
      </ul>
    </template>
  </article>
</template>
<script>
import RenderSource from '../mixins/renderSource'
import RenderPersonTitle from '../mixins/renderPersonTitle'
import Fetch from '../mixins/fetch'
export default {
  mixins: [
    RenderSource,
    RenderPersonTitle,
    Fetch
  ],
  beforeRouteEnter (to, _from, next) {
    next(vm => vm.getPerson(to))
  },
  beforeRouteUpdate (to, _from, next) {
    this.getPerson(to)
    next()
  },
  data () {
    return {
      person: null,
      activeTab: null,
      suggestions: null,
      title: null,
      description: null,
      notFoundText: 'Personen kunde inte hittas'
    }
  },
  methods: {
    getPerson (to) {
      fetch(this.apiUrl + this.subDomain + '/persons/' + this.pPop(to.params.person)).then(data => data.json()).then(data => {
        this.person = data
        this.activeTab = to.meta.activeTab
        this.title = this.getName(this.person, true, true)
        this.description = 'Personakt för ' + this.title + '. ' + this.getOccupation(this.person)
        this.title += ' - ' + this.websiteTitle
      }).catch(() => {
        this.fetchData('/search?q=' + this.nPop(to.params.person) + '&l=5&page=1').then(data => {
          this.suggestions = data
          this.description = this.notFoundText
          this.title = this.notFoundText + ' - ' + this.websiteTitle
        })
      })
    },
    getRelationship (family, parent) {
      const parentType = parent === 'Mother' ? 'Mor' : 'Far'
      const parentTypeLc = parentType.toLowerCase()

      const familyRels = [
        parentType,
        'Adoptiv' + parentTypeLc,
        parentType,
        'Foster' + parentTypeLc,
        parentType, parentType, parentType,
        parentType + ' (omstridd)',
        'Styv' + parentTypeLc,
        'Släkt',
        'Vårdnadshavare',
        'Vederlagd ' + parentTypeLc
      ]

      // eslint-disable-next-line eqeqeq
      return (family.FamilyRel == 0 ? '' : (family.FamilyRel == 1 ? '?' : 'Vederlagd ')) + familyRels[family[parent + 'Rel']] || ''
    }
  }
}
</script>

<style lang="scss">
section {
    &:last-of-type {
      padding-bottom: 0
    }
    padding: 1rem 0rem;
    border-bottom: 1px solid #dee2e6
}
.sources {
    ul, i {
        padding-left: 1rem
    }
}
.error {
    img {
      height: 256px;
      width: 256px
    }
    display:flex;
    flex-direction: column;
    align-items: center
}
.strike {
    text-decoration:line-through
}
.b {
    font-weight:bold
}
.i {
    font-style:italic
}
.super {
    vertical-align: super;
    font-size:smaller
}
img {
  max-width: 1024px
}
picture img {
    max-width: 300px;
    max-height: 300px;
    float:right
}
.altname {
  font-weight: 100;
  font-style: italic;
}
</style>

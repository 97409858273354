<template>
    <router-link @click="$emit('personClicked')" v-if="withLink&&person" :to="'/' + [person[prefix + 'FirstName'], person[prefix + 'LastName']].join(' ').trim().replace(/[^/a-zA-Z0-9åäöÅÄÖ]+/g, '-') + '-' + (person[prefix +'HashCode']||person['id']) + addition" :class="{anc:person.Ancestor}">
      <slot><span v-html="getName(this.person, this.withTitle, this.showPeriod, true)"></span></slot>
    </router-link>
    <template v-else><span v-html="getName(this.person, this.withTitle, this.showPeriod, true)"></span></template>
</template>
<script>
import RenderPersonTitle from '../../mixins/renderPersonTitle'
export default {
  mixins: [
    RenderPersonTitle
  ],
  props: {
    person: null,
    prefix: {
      default: '',
      type: String
    },
    addition: {
      default: '',
      type: String
    },
    showPeriod: {
      default: true,
      type: Boolean
    },
    withLink: {
      default: true,
      type: Boolean
    },
    withTitle: {
      default: false,
      type: Boolean
    }
  }
}
</script>

export default {
  methods: {
    fetchData (url) {
      return fetch(this.apiUrl + this.subDomain + url, { headers: { user: this.subDomain } }).then(data => data.json())
    },
    pPop (url) {
      return url.split('-').pop().replace(/\..*$/, '')
    },
    nPop (url) {
      const n = url.split('-')
      n.pop()
      return n.join(' ')
    }
  }
}
